<template>
  <div class="all">
    <div class="toptext">
      美国著名心理学家加德纳提出，能力是一个人解决实际问题所需的心理条件。能力的形成受到先天条件的影响，比如父母的遗传、大脑皮层的发育；也得益于后天环境的塑造，比如学校的教育、家庭的影响等。加德纳认为，每个人生来就在某种程度上拥有下述相对独立的八种能力，每一种能力在我们的生活与学习中都能发挥其价值，它们都受到了人们的珍视。
    </div>
    <div id="echartss2" class="echartsbox"></div>
    <div class="textdiv">
      每个人的这八种能力有强有弱，有人长于语言表达，有人长于身体运动。那么你的能力情况是怎么样的呢？下面是你的能力测评结果：
    </div>
    <div id="echartss3" class="echartsbox1"></div>
    <div class="comprehensive-bottom-content">
      <div class="comprehensive-bottom">
        <div class="comprehensive-left"></div>
        <div class="comprehensive-text">红色代表你优秀的潜能</div>
      </div>
      <div class="comprehensive-bottom">
        <div class="comprehensive-left1"></div>
        <div class="comprehensive-text">黄色代表你中等的能力</div>
      </div>
      <div class="comprehensive-bottom">
        <div class="comprehensive-left2"></div>
        <div class="comprehensive-text">蓝色代表你较弱的能力</div>
      </div>
    </div>
    <div class="textbox">
      在上图中，得分越高说明你的这项能力越优势，反之，得分越低说明你的这项能力越不足。从图中可以看出：相比而言，你的最优能力分别是：<span
        >{{ abilityechartsdata[0].name }},{{ abilityechartsdata[1].name }},{{
          abilityechartsdata[2].name
        }}</span
      >；其次是<span
        >{{ abilityechartsdata[3].name }}{{ abilityechartsdata[4].name
        }}{{ abilityechartsdata[5].name }}</span
      >；表现较弱的是：<span
        >{{ abilityechartsdata[6].name }}{{ abilityechartsdata[7].name }}</span
      >。
    </div>
    <div class="textbox">
      此外，在上图中，柱子的不同颜色表示不同的涵义：红色表示你此项能力比80%的同龄人高，黄色代表你的该项能力处于同龄人中的中等水平，和大部分人都差不多，蓝色代表你的该项能力比80%的同龄人低，下面是你的具体每项能力的表现：
    </div>
    <div v-for="(item, index) in abilityResults1" :key="index">
      <div class="line"></div>
      <div class="smallbox">
        <div class="smalltitle">{{ item.typeOneName }}能力</div>
        <div class="smalltext">
          <div class="smalldiv">
            <div class="smalldivtitle">特征描述</div>
            <div class="smalldivtext">
              {{ item.descContent }}
            </div>
          </div>
          <div class="smalldiv">
            <div class="smalldivtitle">专业选择建议</div>
            <div class="smalldivtext">
              {{ item.chooseContent }}
            </div>
          </div>
          <div class="smalldiv">
            <div class="smalldivtitle">提升建议</div>
            <div class="smalldivtext">
              {{ item.promoteContent }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    abilityResults: {
      type: Array,
      default: () => [],
    },
    abilityJson: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    abilityJson: {
      immediate: true,
      handler(newValue, oldValue) {
        console.log(newValue, newValue);
        this.abilityechartsdata = newValue;
        this.setecharts();
        this.$nextTick(()=>{
          // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("echartss2"));
        let myChart1 = echarts.init(document.getElementById("echartss3"));
        this.myChart = myChart;
        this.myChart1 = myChart1;
        // 绘制图表，option是数据
        this.option && myChart.setOption(this.option);
        this.option1 && myChart1.setOption(this.option1);
        })
      },
      deep: true,
    },
    abilityResults: {
      immediate: true,
      handler(newValue, oldValue) {
        this.abilityResults1 = newValue;
        this.setecharts();
        this.$nextTick(()=>{
          // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("echartss2"));
        let myChart1 = echarts.init(document.getElementById("echartss3"));
        this.myChart = myChart;
        this.myChart1 = myChart1;
        // 绘制图表，option是数据
        this.option && myChart.setOption(this.option);
        this.option1 && myChart1.setOption(this.option1);
        })
      },
      deep: true,
    },
  },
  data() {
    return {
      myChart: null,
      myChart1: null,
      abilityJpg:"",
      ability2Jpg: "",
      abilityResults1: [],
      abilityechartsdata: [],
      option: {
        title: {
          text: "能力雷达图",
        },
        radar: {
          // shape: "circle",//圆形
          splitNumber: 4, // 雷达图圈数设置
          name: {
            textStyle: {
              color: "#000",
              fontSize: "16px",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 10,
            color: "#5A99FF",
            showMaxLabel: true, //不显示最大值，即外圈不显示数字30
            showMinLabel: false, //显示最小数字，即中心点显示0
          },
          // 设置雷达图中间射线的颜色
          axisLine: {
            lineStyle: {
              color: "rgba(131,141,158,1)",
            },
          },
          indicator: [
            { text: "语言-言语", max: 100 },
            { text: "逻辑-数理", max: 100 },
            { text: "视觉-空间", max: 100 },
            { text: "身体-运动", max: 100 },
            { text: "人际-交往", max: 100 },
            { text: "音乐-节奏", max: 100 },
            { text: "自然-观察", max: 100 },
            { text: "内省-自知", max: 100 },
          ],
          //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
          splitArea: {
            show: false,
            areaStyle: {
              color: "rgba(255,0,0,0)", // 图表背景的颜色
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "rgba(90,153,255,.5)", // 设置网格的颜色
            },
          },
        },
        series: [
          {
            type: "radar",
            areaStyle: {
              // 单项区域填充样式
              normal: {
                color: "rgba(90,153,255,1)", // 填充的颜色。[ default: "#000" ]
              },
            },
            data: [
              {
                value: [80, 87, 58, 68, 78, 60, 78, 25],
                // 设置区域边框和区域的颜色
                itemStyle: {
                  normal: {
                    color: "rgba(255,140,95,1)",
                  },
                },
                //在拐点处显示数值
                label: {
                  normal: {
                    show: true,
                    color: "rgba(255,140,95,1)",
                    formatter: (params) => {
                      return params.value;
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      option1: {
        xAxis: {
          type: "category",
          data: [
            "语言-言语",
            "逻辑-数理",
            "视觉-空间",
            "身体-运动",
            "人际-交往",
            "音乐-节奏",
            "自然-观察",
            "内省-自知",
          ],
        },
        yAxis: {
          type: "value",
          max: 100,
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#5A99FF",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#5A99FF",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#5A99FF",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FF5A5A",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FFB25F",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FFB25F",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FF5A5A",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FFB25F",
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 16,
                  },
                },
              },
            ],
            type: "bar",
          },
        ],
      },
    };
  },
  mounted() {
    this.setecharts();
    // 基于准备好的dom，初始化echarts实例
    let myChart = echarts.init(document.getElementById("echartss2"));
    let myChart1 = echarts.init(document.getElementById("echartss3"));
    this.myChart = myChart;
    this.myChart1 = myChart1;
    // 绘制图表，option是数据
    this.option && myChart.setOption(this.option);
    this.option1 && myChart1.setOption(this.option1);
  },
  methods: {
    setecharts() {
      let valuelist = this.option.series[0].data[0].value;
      console.log(this.abilityechartsdata, "123");
      let data = this.option1.series[0].data;
      for (let i = 0; i < this.abilityechartsdata.length; i++) {
        if (this.abilityechartsdata[i].name == "语言-言语") {
          valuelist[0] = this.abilityechartsdata[i].value;
          data[0].value = this.abilityechartsdata[i].value;
          if (data[0].value > 73) {
            data[0].itemStyle.color = "#FF5A5A";
          } else if (data[0].value < 48) {
            data[0].itemStyle.color = "#5A99FF";
          } else {
            data[0].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "逻辑-数理") {
          valuelist[1] = this.abilityechartsdata[i].value;
          data[1].value = this.abilityechartsdata[i].value;
          if (data[1].value > 70) {
            data[1].itemStyle.color = "#FF5A5A";
          } else if (data[1].value < 37) {
            data[1].itemStyle.color = "#5A99FF";
          } else {
            data[1].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "视觉-空间") {
          valuelist[2] = this.abilityechartsdata[i].value;
          data[2].value = this.abilityechartsdata[i].value;
          if (data[2].value > 73) {
            data[2].itemStyle.color = "#FF5A5A";
          } else if (data[2].value < 43) {
            data[2].itemStyle.color = "#5A99FF";
          } else {
            data[2].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "身体-运动") {
          valuelist[3] = this.abilityechartsdata[i].value;
          data[3].value = this.abilityechartsdata[i].value;
          if (data[3].value > 70) {
            data[3].itemStyle.color = "#FF5A5A";
          } else if (data[3].value < 43) {
            data[3].itemStyle.color = "#5A99FF";
          } else {
            data[3].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "人际-交往") {
          valuelist[4] = this.abilityechartsdata[i].value;
          data[4].value = this.abilityechartsdata[i].value;
          if (data[4].value > 79) {
            data[4].itemStyle.color = "#FF5A5A";
          } else if (data[4].value < 54) {
            data[4].itemStyle.color = "#5A99FF";
          } else {
            data[4].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "音乐-节奏") {
          valuelist[5] = this.abilityechartsdata[i].value;
          data[5].value = this.abilityechartsdata[i].value;
          if (data[5].value > 68) {
            data[5].itemStyle.color = "#FF5A5A";
          } else if (data[5].value < 29) {
            data[5].itemStyle.color = "#5A99FF";
          } else {
            data[5].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "自然-观察") {
          valuelist[6] = this.abilityechartsdata[i].value;
          data[6].value = this.abilityechartsdata[i].value;
          if (data[6].value > 79) {
            data[6].itemStyle.color = "#FF5A5A";
          } else if (data[6].value < 45) {
            data[6].itemStyle.color = "#5A99FF";
          } else {
            data[6].itemStyle.color = "#FFB25F";
          }
        }
        if (this.abilityechartsdata[i].name == "内省-自知") {
          valuelist[7] = this.abilityechartsdata[i].value;
          data[7].value = this.abilityechartsdata[i].value;
          if (data[7].value > 82) {
            data[7].itemStyle.color = "#FF5A5A";
          } else if (data[7].value < 54) {
            data[7].itemStyle.color = "#5A99FF";
          } else {
            data[7].itemStyle.color = "#FFB25F";
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 880px;
  .smallboxlast {
    padding-bottom: 40px;
  }
  .smalldiv {
    margin-top: 30px;
  }
  .smalldivtitle {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    font-weight: 700;
    padding-right: 20px;
    text-align: right;
  }
  .smalldivtext {
    display: inline-block;
    width: 630px;
    font-size: 14px;
    vertical-align: middle;
  }
  .smalltitle {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    font-weight: 700;
    padding-right: 20px;
    text-align: left;
  }
  .smalltext {
    display: inline-block;
    width: 830px;
    font-size: 14px;
    vertical-align: top;
  }
  .line {
    border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .textdiv {
    width: 830px;
    text-indent: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
  }
  .textbox {
    margin-left: 50px;
    width: 830px;
    text-indent: 2em;
    margin-bottom: 20px;
    span {
      font-weight: 700;
    }
  }
  .echartsbox {
    width: 830px;
    height: 420px;
    margin-bottom: 40px;
  }
  .echartsbox1 {
    width: 830px;
    height: 300px;
  }
  .toptext {
    text-indent: 2em;
    font-size: 14px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .comprehensive-bottom-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 880px;
  }
  .comprehensive-bottom {
    margin-right: 30rpx;
    .comprehensive-left {
      width: 26px;
      height: 26px;
      background-color: #ff5a5a;
      display: inline-block;
    }
    .comprehensive-left1 {
      width: 26px;
      height: 26px;
      background-color: #ffb25f;
      display: inline-block;
    }
    .comprehensive-left2 {
      width: 26px;
      height: 26px;
      background-color: #5a99ff;
      display: inline-block;
    }
    .comprehensive-text {
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;
    }
  }
}
</style>