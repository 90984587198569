<template>
    <div>
        <div class="smallbox">
          <div class="smalltitle">对应专业方向</div>
          <div class="smalltext">{{interestRecommend.jobDirectionContent? interestRecommend.jobDirectionContent : ''}}</div>
        </div>
         <div class="line"></div>
         <div class="smallboxlast">
          <div class="smalltitle">对应职业方向</div>
          <div class="smalltext">{{interestRecommend.proDirectionContent? interestRecommend.jobDirectionContent : ''}}</div>
        </div>
    </div>
</template>

<script>
export default {
  props:{
    interestRecommend:{
      type: Object,
      default: () => {},
    }
  },
    data(){
        return{

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.smallboxlast{
      padding-bottom: 40px;
  }
.smalltitle{
  display: inline-block;
  width: 150px;
  font-size: 14px;
  font-weight: 700;
  padding-right: 50px;
  text-align: right;
}
.smalltext{
    display: inline-block;
    width: 630px;
    font-size: 12px;
    vertical-align: top;
}
.line{
      border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
      margin-top: 20px;
  }
</style>