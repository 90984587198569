<template>
  <div class="bigbox">
      <el-tabs type="border-card">
      <el-tab-pane label="兴趣">
        <interest
          v-if="data.isInterest == 1"
          :interestResults="interestResults"
          :interestJson="interestJson"
        />
      </el-tab-pane>
      <el-tab-pane label="性格">
        <character
          v-if="data.isCharacter == 1"
          :characterResults="characterResults"
          :characterJson="characterJson"
        />
      </el-tab-pane>
      <el-tab-pane label="能力">
        <ability v-if="data.isAbility == 1" :abilityResults="abilityResults" :abilityJson="abilityJson"/>
      </el-tab-pane>
      <el-tab-pane label="专业推荐">
        <professionalRecommendation
          v-if="data.isInterest == 1"
          :interestRecommend="interestRecommend"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import interest from "./components/interest";
import character from "./components/character";
import professionalRecommendation from "./components/professionalRecommendation";
import ability from "./components/ability";
import {
  getAnswerResult,
} from "@/api/comprehensive";
export default {
    components: {
    interest,
    character,
    professionalRecommendation,
    ability,
  },
  data() {
    return {
        data: {},
        characterResults: {},
        abilityResults: {},
        interestRecommend:{},
        interestJson: [],
        characterJson: [],
        abilityJson: [],
        interestResults: {},
    };
  },
  mounted(){
      let id = this.$route.query.id
      if(id){
          this.getAnswerResult(id)
      }
  },
  methods:{
      getAnswerResult(id) {
      getAnswerResult({ id: id }).then((res) => {
        this.data = res.data || {};
        this.characterResults = this.data.characterResults || {};
        this.abilityResults = this.data.abilityResults || {};
        this.interestRecommend = this.data.interestRecommend || {};
        this.interestJson = JSON.parse(this.data.interestJson) || [];
        this.characterJson = JSON.parse(this.data.characterJson) || [];
        this.abilityJson = JSON.parse(this.data.abilityJson) || [];
        this.interestResults = this.data.interestResults || {};
      });
    },
  }
};
</script>

<style lang="less" scoped>
.bigbox{
    width: 910px;
}
/deep/.el-tabs__item.is-active {
    color: #1AB394!important;
}
/deep/.el-tabs__item:hover{
    color: #1AB394!important;
}
/deep/.el-tabs__active-bar{
    background-color:  #1AB394!important;
}
</style>
