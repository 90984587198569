<template>
  <div class="all">
    <p>
      心理学研究表明：无论是学业还是职业，人只有在与自己兴趣类型相吻合的环境中才能够取得最好的发挥自己的潜能，产生最强的成就感与满足感。著名的生涯发展理论家Holland把人的职业兴趣分为如下六种类型：
    </p>
    <div class="table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center','font-size': '14px' }"
        :cell-style="{ 'text-align': 'center','font-size': '14px' }"
      >
        <el-table-column prop="type" label="兴趣特点"> </el-table-column>
        <el-table-column prop="td" label="典型特点"> </el-table-column>
        <el-table-column prop="zyl" label="典型专业类"> </el-table-column>
        <el-table-column prop="zy" label="典型职业"> </el-table-column>
      </el-table>
    </div>
    <div class="title">经过测评，你的职业兴趣分数如下：</div>
    <!-- echartss -->
    <div id="echartss" style="width: 880px; height: 500px"></div>
    <!-- echartss -->
    <div class="comprehensive-bottom-content">
      <div class="comprehensive-bottom">
        <div class="comprehensive-left"></div>
        <div class="comprehensive-text">红色代表此类型兴趣最强</div>
      </div>
      <div class="comprehensive-bottom">
        <div class="comprehensive-left1"></div>
        <div class="comprehensive-text">黄色代表此类型兴趣中等程度</div>
      </div>
      <div class="comprehensive-bottom">
        <div class="comprehensive-left2"></div>
        <div class="comprehensive-text">蓝色代表此类型兴趣较弱</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">基本兴趣特点</div>
      <div class="smalltext">{{ interestResults1.characteristicContent }}</div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">兴趣强弱程度</div>
      <div class="smalltext">{{ interestResults1.degreeContent }}</div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">适宜的成长环境</div>
      <div class="smalltext">{{ interestResults1.environmentContent }}</div>
    </div>
    <div class="line"></div>
    <div class="smallboxlast">
      <div class="smalltitle">感兴趣的领域</div>
      <div class="smalltext">{{ interestResults1.fieldContent }}</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    interestResults: {
      type: Object,
      default: () => {},
    },
    interestJson: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    interestJson: {
      immediate: true,
      handler(newValue, oldValue) {
        console.log('兴趣改变');
        this.interestechartsdata = newValue;
        this.setecharts();
        this.$nextTick(()=>{
        let myChart = echarts.init(document.getElementById("echartss"), "light");
        this.myChart = myChart;
        // 绘制图表，option是数据
        this.option && myChart.setOption(this.option);
        })
      },
      deep: true,
    },
    interestResults:{
      immediate: true,
      handler(newValue, oldValue) {
       this.interestResults1 = newValue
       this.setecharts();
       this.$nextTick(()=>{ 
       // 基于准备好的dom，初始化echarts实例
       let myChart = echarts.init(document.getElementById("echartss"), "light");
       this.myChart = myChart;
       // 绘制图表，option是数据
       this.option && myChart.setOption(this.option);
       })
      },
      deep: true,
    }
  },
  data() {
    return {
      interestJpg:"",
      interestResults1: {},
      interestechartsdata: [],
      tableData: [
        {
          type: "现实型",
          td: "喜欢动手操作，对机械或工具很感兴趣，务实",
          zyl: "机械类、建筑类等",
          zy: "工程师、技术人员等",
        },
        {
          type: "研究型",
          td: "喜欢思考,探索,分析问题,具有钻研精神",
          zyl: "物理学类、哲学类等",
          zy: "科学家、大学教授等",
        },
        {
          type: "艺术型",
          td: "喜欢表达和创新,具有想象力,追求多变,自由",
          zyl: "艺术类、外语类等",
          zy: "艺术家、设计师等",
        },
        {
          type: "社会型",
          td: "喜欢帮助他人,善解人意,富有爱心,同情心",
          zyl: "教育学类、心理学类等",
          zy: "教师、社会工作者等",
        },
        {
          type: "企业型",
          td: "喜欢影响和说服他人,具有组织领导才能,有活力",
          zyl: "管理学类、经济学类等",
          zy: "市场部经理、销售人员等",
        },
        {
          type: "常规型",
          td: "喜欢做事出有明确具体的规定与计划,严谨细心",
          zyl: "经济学类、管理学类等",
          zy: "会计、税务员等",
        },
      ],
      option: {
        xAxis: {
          type: "category",
          data: ["现实型", "研究型", "艺术型", "社会型", "企业型", "常规型"],
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100,
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#5A99FF",
                },
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
								}
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FF5A5A",
                },
                label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FFB25F",
                },
                label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FFB25F",
                },
                label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FF5A5A",
                },
                label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FFB25F",
                },
                label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
                },
              },
            ],
            type: "bar",
          },
        ],
      },
      myChart:null
    };
  },
  mounted() {
    this.setecharts();
    // 基于准备好的dom，初始化echarts实例
    let myChart = echarts.init(document.getElementById("echartss"), "light");
    this.myChart = myChart;
    // 绘制图表，option是数据
    this.option && myChart.setOption(this.option);
  },
  methods: {
    setecharts() {
      console.log(this.interestechartsdata,'123');
      let data = this.option.series[0].data
      for(let i =0; i< this.interestechartsdata.length; i++){
        if(this.interestechartsdata[i].name == "现实型"){
          data[0].value = this.interestechartsdata[i].value
          if(data[0].value>63){
            data[0].itemStyle.color = "#FF5A5A"
          }else if(data[0].value<43){
            data[0].itemStyle.color = "#5A99FF"
          }else{
            data[0].itemStyle.color = "#FFB25F"
          }
        }
        if(this.interestechartsdata[i].name == "研究型"){
          data[1].value = this.interestechartsdata[i].value
          if(data[1].value>73){
            data[1].itemStyle.color = "#FF5A5A"
          }else if(data[1].value<46){
            data[1].itemStyle.color = "#5A99FF"
          }else{
            data[1].itemStyle.color = "#FFB25F"
          }
        }
        if(this.interestechartsdata[i].name == "艺术型"){
          data[2].value = this.interestechartsdata[i].value
          if(data[2].value>64){
            data[2].itemStyle.color = "#FF5A5A"
          }else if(data[2].value<36){
            data[2].itemStyle.color = "#5A99FF"
          }else{
            data[2].itemStyle.color = "#FFB25F"
          }
        }
        if(this.interestechartsdata[i].name == "社会型"){
          data[3].value = this.interestechartsdata[i].value
          if(data[3].value>75){
            data[3].itemStyle.color = "#FF5A5A"
          }else if(data[3].value<57){
            data[3].itemStyle.color = "#5A99FF"
          }else{
            data[3].itemStyle.color = "#FFB25F"
          }
        }
        if(this.interestechartsdata[i].name == "企业型"){
          data[4].value = this.interestechartsdata[i].value
          if(data[4].value>68){
            data[4].itemStyle.color = "#FF5A5A"
          }else if(data[4].value<48){
            data[4].itemStyle.color = "#5A99FF"
          }else{
            data[4].itemStyle.color = "#FFB25F"
          }
        }
        if(this.interestechartsdata[i].name == "常规型"){
          data[5].value = this.interestechartsdata[i].value
          if(data[5].value>57){
            data[5].itemStyle.color = "#FF5A5A"
          }else if(data[5].value<39){
            data[5].itemStyle.color = "#5A99FF"
          }else{
            data[5].itemStyle.color = "#FFB25F"
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 880px;
  box-sizing: border-box;
}
.smallboxlast {
  padding-bottom: 40px;
}
// table边框颜色
/deep/.el-table--border:after,
/deep/.el-table--group:after,
/deep/.el-table:before {
  background-color: #ffb25f !important;
}

/deep/.el-table--border,
/deep/.el-table--group {
  border-color: #ffb25f !important;
}

/deep/.el-table td,
/deep/.el-table th.is-leaf {
  border-bottom: 1px solid #ffb25f !important;
}

/deep/.el-table--border th,
/deep/.el-table--border th.gutter:last-of-type {
  border-bottom: 1px solid #ffb25f !important;
}

/deep/.el-table--border td,
/deep/.el-table--border th {
  border-right: 1px solid #ffb25f !important;
}
.smalltitle {
  display: inline-block;
  width: 150px;
  font-size: 14px;
  font-weight: 700;
  padding-right: 50px;
  text-align: right;
}
.smalltext {
  display: inline-block;
  width: 630px;
  font-size: 12px;
  vertical-align: top;
}
p {
  text-indent: 2em;
  margin-bottom: 20px;
}
.line {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}
.comprehensive-bottom-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  width: 880px;
}
.comprehensive-bottom {
  margin-right: 30rpx;
  .comprehensive-left {
    width: 26px;
    height: 26px;
    background-color: #ff5a5a;
    display: inline-block;
  }
  .comprehensive-left1 {
    width: 26px;
    height: 26px;
    background-color: #ffb25f;
    display: inline-block;
  }
  .comprehensive-left2 {
    width: 26px;
    height: 26px;
    background-color: #5a99ff;
    display: inline-block;
  }
  .comprehensive-text {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
  }
}
</style>