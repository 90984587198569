<template>
  <div class="all">
    <div class="toptext">
      人的性格倾向，就象分别使用自己的两只手写字一样，都可以写出来，但惯用的那只会写得更自然，写得更好看。那么你的性格倾向是什么呢？看看以下的描述像不像你。
      测试结果显示，你的性格类型是：
      <span class="topspan"
        >{{ characterResults1.typeOne }}—{{ characterResults1.typeTwo }}—{{
          characterResults1.typeThree
        }}—{{ characterResults1.typeFour }}</span
      >
    </div>
    <div id="echartss1" class="echartsbox"></div>
    <div class="smallbox">
      <div class="smalltitle">性格类型结果</div>
      <div class="smalltext">
        {{ characterResults1.typeResults }}
      </div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">基本描述</div>
      <div class="smalltext">
        {{ characterResults1.descContent }}
      </div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">可能的盲点</div>
      <div class="smalltext">
        {{ characterResults1.blindnessContent }}
      </div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">你的优势</div>
      <div class="smalltext">
        {{ characterResults1.advantageContent }}
      </div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">你的不足</div>
      <div class="smalltext">
        {{ characterResults1.insufficientContent }}
      </div>
    </div>
    <div class="line"></div>
    <div class="smallbox">
      <div class="smalltitle">你未来的职业特质</div>
      <div class="smalltext">
        {{ characterResults1.traitContent }}
      </div>
    </div>
    <div class="line"></div>
    <div class="smallboxlast">
      <div class="smalltitle">对你的发展建议</div>
      <div class="smalltext">
        {{ characterResults1.adviceContent }}
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    characterResults: {
      type: Object,
      default: () => {},
    },
    characterJson: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    characterJson: {
      immediate: true,
      handler(newValue, oldValue) {
        this.characterechartsdata = newValue;
        this.setecharts();
        this.$nextTick(()=>{
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("echartss1"));
        this.myChart = myChart;
        // 绘制图表，option是数据
        this.option && myChart.setOption(this.option);
        })
      },
      deep: true,
    },
    characterResults: {
      immediate: true,
      handler(newValue, oldValue) {
        this.characterResults1 = newValue;
        this.setecharts();
        this.$nextTick(()=>{
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("echartss1"));
        this.myChart = myChart;
        // 绘制图表，option是数据
        this.option && myChart.setOption(this.option);
        })
      },
      deep: true,
    },
  },
  data() {
    return {
      myChart: null,
      characterJpg: "",
      characterResults1:{},
      characterechartsdata: [],
      option: {
        title: {
          text: "性格",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (params) => {
            if (!params.length) return "";
            let s = params[0].axisValueLabel;
            for (const iterator of params) {
              // 如果是负数则反转
              let d =
                iterator.data.value < 0
                  ? -iterator.data.value
                  : iterator.data.value;
              s += "：" + d + "<br/>";
            }
            return s;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          max: 50,
          min: -50,
          type: "value",
          position: "top",
          // splitLine: {
          //   lineStyle: {
          //     type: "dashed",
          //   },
          // },
        },
        yAxis: [
          {
            splitLine: { show: true },
            type: "category",
            axisTick: {
              show: false,
            },
            // "判断",  "思考","感觉", "外向"
            data: ["判断", "思考", "感觉", "外向"]
          },
          {
            type: "category",
            axisTick: {
              show: false,
            },
            //"知觉","情感","直觉","内向"
            data: ["知觉", "情感", "直觉", "内向"]
          },
        ],
        series: [
          {
            type: "bar",
            label: {
              show: true,
              formatter: (value) => {
                // 值都是负数的 所以需要取反一下
                return value.value > 0 ? value.value : -value.value;
              },
            },
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "rgb(193, 35, 43)",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "rgb(181, 195, 52)",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "rgb(252, 206, 16)",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "rgb(232, 124, 37)",
                },
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.setecharts();
    // 基于准备好的dom，初始化echarts实例
    let myChart = echarts.init(document.getElementById("echartss1"));
    this.myChart = myChart;
    // 绘制图表，option是数据
    this.option && myChart.setOption(this.option);
  },
  methods: {
    setecharts() {
      let data = this.option.series[0].data;
      console.log(this.characterechartsdata, " this.characterechartsdata");
      for (let i = 0; i < this.characterechartsdata.length; i++) {
        if (
          this.characterechartsdata[i].name[0] == "外向" ||
          this.characterechartsdata[i].name[1] == "外向"
        ) {
          data[3].value = -this.characterechartsdata[i].value;
        }
        if (
          this.characterechartsdata[i].name[0] == "感觉" ||
          this.characterechartsdata[i].name[1] == "感觉"
        ) {
          data[2].value = -this.characterechartsdata[i].value;
        }
        if (
          this.characterechartsdata[i].name[0] == "思考" ||
          this.characterechartsdata[i].name[1] == "思考"
        ) {
          data[1].value = -this.characterechartsdata[i].value;
        }
        if (
          this.characterechartsdata[i].name[0] == "判断" ||
          this.characterechartsdata[i].name[1] == "判断"
        ) {
          data[0].value = -this.characterechartsdata[i].value;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 880px;
  box-sizing: border-box;
  .echartsbox {
    width: 880px;
    height: 500px;
    margin-bottom: 40px;
  }
  .smalltitle {
    display: inline-block;
    width: 150px;
    font-size: 14px;
    font-weight: 700;
    padding-right: 50px;
    text-align: right;
  }
  .smallboxlast {
    padding-bottom: 40px;
  }
  .smalltext {
    display: inline-block;
    width: 630px;
    font-size: 12px;
    vertical-align: top;
  }
  .line {
    border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.toptext {
  text-indent: 2em;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .topspan {
    color: #ffb25f;
    font-weight: 700;
  }
}
</style>